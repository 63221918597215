import logo from './logo.svg';
import './App.css';
import WalletCard from './pages/WalletCard';
import WalletCardEthers from'./pages/WalletCardEthers';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {

  return (
    <div className="App">
      <Header />
      <WalletCardEthers />
      <Footer />
    </div>
  );
}
{/* <WalletCard/> */}

export default App;
