import React, { Component } from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer class="footer__1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 space-y-20">
                                <div class="footer__logo">
                                    <a href="/">
                                        <img src="assets/img/logos/Logo.svg" alt="logo" id="logo_js_f" />
                                    </a>
                                </div>
                                <p class="footer__text">
                                    raroin is a shared liquidity NFT market smart contract
                                </p>
                                <div>
                                    <ul class="footer__social space-x-10 mb-40">
                                        <li> <a href=""> <i class="ri-facebook-line"></i> </a>
                                        </li>
                                        <li> <a href=""> <i class="ri-messenger-line"></i> </a>
                                        </li>
                                        <li> <a href=""> <i class="ri-whatsapp-line"></i> </a>
                                        </li>
                                        <li> <a href=""> <i class="ri-youtube-line"></i> </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6">
                                <h6 class="footer__title">Raroin</h6>
                                <ul class="footer__list">
                                    <li> <a href="Home1.html"> Home1 </a>
                                    </li>
                                    <li> <a href="Home2.html"> Home2
                                    </a> </li>
                                    <li> <a href="Home3.html"> Home3 </a> </li>
                                    <li> <a href="Marketplace.html"> Marketplace
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-2 col-6">
                                <h6 class="footer__title">Assets</h6>
                                <ul class="footer__list">
                                    <li> <a href="Profile.html"> Profile </a>
                                    </li>
                                    <li> <a href="Creators.html"> Creators </a>
                                    </li>
                                    <li> <a href="Collections.html"> Colletctions </a>
                                    </li>
                                    <li> <a href="Activity.html"> Activity
                                    </a> </li>
                                </ul>
                            </div>
                            <div class="col-lg-2 col-6">
                                <h6 class="footer__title">Company</h6>
                                <ul class="footer__list">
                                    <li> <a href="Upload-type.html"> Upload Types </a>
                                    </li>
                                    <li> <a href="Upload.html"> Upload </a> </li>
                                    <li> <a href="Connect-wallet.html"> Connect wallet
                                    </a> </li>
                                    <li> <a href="Item-details.html"> Item details </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p class="copyright text-center">
                            Copyright © 2021. Created with love by creabik.
                        </p>
                    </div>
                </footer>
                <script src="assets/js/jquery-3.6.0.js"></script>
                <script src="assets/js/bootstrap.bundle.min.js"></script>
                <script src="assets/js/swiper-bundle.min.js"></script>
                <script src="assets/js/gsap.min.js"></script>
                <script src="assets/js/ScrollTrigger.min.js"></script>
                <script src="assets/js/sticky-sidebar.js"></script>
                <script src="assets/js/script.js"></script>
                <script src="https://unpkg.com/moralis/dist/moralis.js"></script>
            </div>
        )
    }
}

export default Footer;