import React, { Component } from 'react';

class Header extends React.Component{
    render(){
        return(
            <div>
                <header class="header__1 js-header" id="header">
                    <div class="container">
                        <div class="wrapper js-header-wrapper">
                            <div class="header__logo">
                                <a href="/">
                                    <img
                                        class="header__logo"
                                        id="logo_js"
                                        src="assets/img/logos/Logo.svg"
                                        alt="logo"
                                    />
                                </a>
                            </div>
                            <div class="header__menu">
                                <ul class="d-flex space-x-20">
                                    <li class="has_popup">
                                        <a class="color_black" href="#">Homes <i class="ri-more-2-fill"></i></a>
                                        <ul class="menu__popup space-y-20">
                                            <li>
                                                <a href="Home1.html">
                                                    <i class="ri-home-smile-2-line"></i>
                                                    Home page 1
                                                </a>
                                            </li>
                                            <li>
                                                <a href="Home2.html">
                                                    <i class="ri-home-2-line"></i> Home page 2</a>
                                            </li>
                                            <li>
                                                <a href="Home3.html">
                                                    <i class="ri-home-5-line"></i> Home page 3</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a class="color_black" href="Marketplace.html"> Marketplace</a>
                                    </li>
                                    <li>
                                        <a class="color_black" href="Collections.html"> Collections</a>
                                    </li>
                                    <li>
                                        <a class="color_black" href="Profile.html"> Profile</a>
                                    </li>
                                    <li>
                                        <a class="color_black" href="Creators.html"> Creators</a>
                                    </li>
                                    <li>
                                        <a class="color_black" href="kit.html"> Ui Kit </a>
                                    </li>
                                    <li class="has_popup2">
                                        <a class="color_black is_new" href="#">Pages <i class="ri-more-2-fill"></i></a>
                                        <ul class="menu__popup2 space-y-20">
                                            <div class="row sub_menu_row">

                                                <div class="col-lg-6 space-y-10">

                                                    <li>
                                                        <a href="Activity.html">
                                                            <i class="ri-line-chart-line"></i>
                                                            Activity
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="edit_profile.html">
                                                            <i class="ri-edit-line"></i>
                                                            Edit Profile
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="Item-details.html">
                                                            <i class="ri-gallery-line"></i>
                                                            Item details
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Live_Auctions.html">
                                                            <i class="ri-auction-line"></i>
                                                            Live Auctions
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Upcoming_projects.html">
                                                            <i class="ri-upload-line"></i>
                                                            Upcoming projects
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="ranking.html">
                                                            <i class="ri-funds-line"></i>
                                                            Ranking
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="is_new" href="newsletter.html">
                                                            <i class="ri-mail-open-line"></i>
                                                            Newsletter
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="forum.html">
                                                            <i class="ri-discuss-line"></i>
                                                            Forum & community
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="post_details.html">
                                                            <i class="ri-chat-check-line"></i>
                                                            Forum details
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="no_results.html">
                                                            <i class="ri-file-search-line"></i>
                                                            No Result
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="is_new" href="Contact.html">
                                                            <i class="ri-customer-service-2-line"></i>
                                                            Contact
                                                        </a>
                                                    </li>

                                                </div>







                                                <div class="col-lg-6 space-y-10">


                                                    <li>
                                                        <a href="Upload-type.html">
                                                            <i class="ri-upload-line"></i>
                                                            Upload Types
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Connect-wallet.html">
                                                            <i class="ri-wallet-3-line"></i>
                                                            Connect wallet
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="questions.html">
                                                            <i class="ri-question-line"></i>
                                                            FAQ
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Submit_request.html">
                                                            <i class="ri-share-forward-line"></i>
                                                            Submit request
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Submit_request.html">
                                                            <i class="ri-message-3-line"></i>
                                                            Request chat
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="blog.html">
                                                            <i class="ri-layout-line"></i>
                                                            Blog
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="article.html">
                                                            <i class="ri-newspaper-line"></i>
                                                            Article
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="register.html">
                                                            <i class="ri-lock-line"></i>
                                                            Register
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="login.html">
                                                            <i class="ri-shield-user-line"></i>
                                                            Login
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Privacy.html">
                                                            <i class="ri-file-text-line"></i>
                                                            Privacy Policy
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a c href="404.html">
                                                            <i class="ri-file-damage-line"></i>
                                                            404
                                                        </a>
                                                    </li>
                                                </div>
                                            </div>						</ul>
                                    </li>
                                </ul>
                            </div>

                            <div class="header__search">
                                <input type="text" placeholder="Search" />
                                <button class="header__result">
                                    <i class="ri-search-line"></i>
                                </button>
                            </div>
                            <div class="header__btns">
                                {/* <a href="" id="connectbtn">
                                    <img width="45" src="assets/img/icons/metamask.svg" alt="" />
                                </a> */}
                            </div>
                            <div class="header__burger js-header-burger"></div>

                            <div class="header__mobile js-header-mobile">
                                <div class="header__mobile__menu space-y-40">
                                    <ul class="d-flex space-y-20">
                                        <li> <a class="color_black" href="Marketplace.html"> Marketplace</a> </li>
                                        <li> <a class="color_black" href="Collections.html"> Collections</a> </li>
                                        <li> <a class="color_black" href="Profile.html"> Profile</a> </li>
                                        <li> <a class="color_black" href="Creators.html"> Creators</a> </li>

                                    </ul>
                                    <div class="space-y-20">
                                        <div class="header__search in_mobile w-full">
                                            <input type="text" placeholder="Search" />
                                            <button class="header__result">
                                                <i class="ri-search-line"></i>
                                            </button>
                                        </div>
                                        <a class="btn btn-grad btn-sm" href="Connect-wallet.html">Connect
                                            wallet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <header class="header__1 js-header header_wallet" id="header_admin">
                    <div class="container">
                        <div class="wrapper js-header-wrapper space-x-10">
                            <div class="header__logo">
                                <a href="/">
                                    <img
                                        class="header__logo"
                                        id="logo_js"
                                        src="assets/img/logos/Logo.svg"
                                        alt="logo"
                                    />
                                </a>
                            </div>

                            <div class="header__menu">
                                <ul class="d-flex space-x-20">
                                    <li class="has_popup">
                                        <a class="color_black" href="#">Homes <i class="ri-more-2-fill"></i></a>
                                        <ul class="menu__popup space-y-20">
                                            <li>
                                                <a href="Home1.html">
                                                    <i class="ri-home-smile-2-line"></i>
                                                    Home page 1
                                                </a>
                                            </li>
                                            <li>
                                                <a href="Home2.html">
                                                    <i class="ri-home-2-line"></i> Home page 2</a>
                                            </li>
                                            <li>
                                                <a href="Home3.html">
                                                    <i class="ri-home-5-line"></i> Home page 3</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li> <a class="color_black" href="Marketplace.html"> Marketplace</a> </li>
                                    <li> <a class="color_black" href="Collections.html"> Collections</a> </li>
                                    <li> <a class="color_black" href="Profile.html"> Profile</a> </li>
                                    <li> <a class="color_black" href="Creators.html"> Creators</a> </li>
                                    <li> <a class="color_black" href="kit.html"> Ui Kit </a> </li>
                                    <li class="has_popup2">
                                        <a class="color_black is_new" href="#">Pages <i class="ri-more-2-fill"></i></a>
                                        <ul class="menu__popup2 space-y-20">
                                            <div class="row sub_menu_row">

                                                <div class="col-lg-6 space-y-10">

                                                    <li>
                                                        <a href="Activity.html">
                                                            <i class="ri-line-chart-line"></i>
                                                            Activity
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="edit_profile.html">
                                                            <i class="ri-edit-line"></i>
                                                            Edit Profile
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="Item-details.html">
                                                            <i class="ri-gallery-line"></i>
                                                            Item details
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Live_Auctions.html">
                                                            <i class="ri-auction-line"></i>
                                                            Live Auctions
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Upcoming_projects.html">
                                                            <i class="ri-upload-line"></i>
                                                            Upcoming projects
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="ranking.html">
                                                            <i class="ri-funds-line"></i>
                                                            Ranking
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="is_new" href="newsletter.html">
                                                            <i class="ri-mail-open-line"></i>
                                                            Newsletter
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="forum.html">
                                                            <i class="ri-discuss-line"></i>
                                                            Forum & community
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="post_details.html">
                                                            <i class="ri-chat-check-line"></i>
                                                            Forum details
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="no_results.html">
                                                            <i class="ri-file-search-line"></i>
                                                            No Result
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="is_new" href="Contact.html">
                                                            <i class="ri-customer-service-2-line"></i>
                                                            Contact
                                                        </a>
                                                    </li>

                                                </div>







                                                <div class="col-lg-6 space-y-10">


                                                    <li>
                                                        <a href="Upload-type.html">
                                                            <i class="ri-upload-line"></i>
                                                            Upload Types
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Connect-wallet.html">
                                                            <i class="ri-wallet-3-line"></i>
                                                            Connect wallet
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a href="questions.html">
                                                            <i class="ri-question-line"></i>
                                                            FAQ
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Submit_request.html">
                                                            <i class="ri-share-forward-line"></i>
                                                            Submit request
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="Submit_request.html">
                                                            <i class="ri-message-3-line"></i>
                                                            Request chat
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="blog.html">
                                                            <i class="ri-layout-line"></i>
                                                            Blog
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="is_new" href="article.html">
                                                            <i class="ri-newspaper-line"></i>
                                                            Article
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="register.html">
                                                            <i class="ri-lock-line"></i>
                                                            Register
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="login.html">
                                                            <i class="ri-shield-user-line"></i>
                                                            Login
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="Privacy.html">
                                                            <i class="ri-file-text-line"></i>
                                                            Privacy Policy
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a c href="404.html">
                                                            <i class="ri-file-damage-line"></i>
                                                            404
                                                        </a>
                                                    </li>
                                                </div>
                                            </div>						</ul>
                                    </li>
                                </ul>
                            </div>

                            <div class="header__search">
                                <input type="text" placeholder="Search" />
                                <button class="header__result">
                                    <i class="ri-search-line"></i>
                                </button>
                            </div>
                            <div class="d-flex align-items-center space-x-20">
                                <div class="header__notifications">
                                    <div class="js-notifications-icon">
                                        <i class="ri-notification-3-line"></i>
                                    </div>
                                    <div class="notifications_popup space-y-20">
                                        <div class="d-flex justify-content-between">
                                            <h5> Notifications</h5>
                                            <a href="Activity.html" class="badge color_white">View all</a>
                                        </div>
                                        <div
                                            class="item
							space-x-20
							d-flex
							justify-content-between
							align-items-center">
                                            <img
                                                class="thumb"
                                                src="assets/img/notifications/1.png"
                                                alt="..."
                                            />
                                            <div class="details">
                                                <a href="activity.html"> <h6>Money revieved</h6> </a>
                                                <p>0.6 ETH</p>
                                            </div>
                                            <span class="circle"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="header__avatar">
                                    <div class="price">
                                        <span>2.45 <strong>ETH</strong> </span>
                                    </div>
                                    <img
                                        class="avatar"
                                        src="assets/img/avatars/avatar_2.png"
                                        alt="avatar"
                                    />
                                    <div class="avatar_popup space-y-20">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <span> 13b9ebda035r178... </span>
                                            <a href="/" class="ml-2">
                                                <i class="ri-file-copy-line"></i>
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center space-x-10">
                                            <img
                                                class="coin"
                                                src="assets/img/logos/coin.svg"
                                                alt="/"
                                            />
                                            <div class="info">
                                                <p class="text-sm font-book text-gray-400">Balance</p>
                                                <p class="w-full text-sm font-bold text-green-500">16.58 ETH</p>
                                            </div>
                                        </div>
                                        <div class="hr"></div>
                                        <div class="links space-y-10">
                                            <a href="#">
                                                <i class="ri-landscape-line"></i> <span> My items</span>
                                            </a>
                                            <a href="edit_profile.html">
                                                <i class="ri-pencil-line"></i> <span> Edit Profile</span>
                                            </a>
                                            <a href="#">
                                                <i class="ri-logout-circle-line"></i> <span> Logout</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="header__btns">
                                    <a class="btn btn-primary btn-sm" href="Upload-type.html">Create</a>
                                </div>
                                <div class="header__burger js-header-burger"></div>
                            </div>
                            <div class="header__mobile js-header-mobile">
                                <div class="header__mobile__menu space-y-40">
                                    <ul class="d-flex space-y-20">
                                        <li> <a class="color_black" href="Marketplace.html"> Marketplace</a> </li>
                                        <li> <a class="color_black" href="Collections.html"> Collections</a> </li>
                                        <li> <a class="color_black" href="Profile.html"> Profile</a> </li>
                                        <li> <a class="color_black" href="Creators.html"> Creators</a> </li>

                                    </ul>
                                    <div class="space-y-20">
                                        <div class="header__search in_mobile w-full">
                                            <input type="text" placeholder="Search" />
                                            <button class="header__result">
                                                <i class="ri-search-line"></i>
                                            </button>
                                        </div>
                                        <a class="btn btn-grad btn-sm" href="Connect-wallet.html">Connect
                                            wallet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default Header;