import React, {useState, useEffect} from 'react';
import {ethers} from 'ethers';
import './WalletCard.css';

const WalletCardEthers = () => {

	const [errorMessage, setErrorMessage] = useState(null);
	const [defaultAccount, setDefaultAccount] = useState(null);
	const [accountinfo, setAccountInfo] = useState(null);
	const [userBalance, setUserBalance] = useState(null);
	const [connButtonText, setConnButtonText] = useState('Connect Wallet');
	const [provider, setProvider] = useState(null);

	const connectWalletHandler = () => {
		if (window.ethereum && defaultAccount == null) {
			// set ethers provider
			setProvider(new ethers.providers.Web3Provider(window.ethereum));

			// connect to metamask
			window.ethereum.request({ method: 'eth_requestAccounts'})
			.then(result => {
				setConnButtonText('Wallet Connected');
				setDefaultAccount(result[0]);
				setAccountInfo('Address: ' + result[0]);
			})
			.catch(error => {
				setErrorMessage(error.message);
			});

		} else if (!window.ethereum){
			console.log('Need to install MetaMask');
			setErrorMessage('Please install MetaMask browser extension to interact');
		}
	}

useEffect(() => {
	if(defaultAccount){
	provider.getBalance(defaultAccount)
	.then(balanceResult => {
		setUserBalance('Balance: ' + ethers.utils.formatEther(balanceResult));
	})
	};
}, [defaultAccount]);
	
	return (
		<div className='walletCard'>
			<div class="overflow-hidden">
				<div class="hero__1">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<div class="hero__left space-y-20">
									<h2 class="hero__title"> Connection to MetaMask using ethers.js </h2>

									<p class="hero__text txt">Account Information
											<div className='accountDisplay'>
												<h5>{accountinfo}</h5>
											</div>
											<div className='balanceDisplay'>
												<h5>{userBalance}</h5>
											</div>
											{errorMessage} 
									</p>
									<div class="space-x-20 d-flex flex-column flex-md-row
									sm:space-y-20">
										<a class="btn btn-grad btn-sm" href="javascript:void(0)" onClick={connectWalletHandler}><i class="ri-wallet-3-line"></i> {connButtonText}</a>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<img class="img-fluid w-full" id="img_js"
									src="assets/img/bg/in_hero1.png"
									alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section mt-100">
				<div class="container">
					<div class="section__head">
						<div class="d-flex justify-content-between align-items-center">
							<h2 class="section__title"> Collections</h2>
							<a href="javascript:void(0)" class="btn btn-dark btn-sm">
								View
								All</a>
						</div>
					</div>

					<div class="row justify-content-center mb-30_reset">
						<div class="col-lg-4 col-md-6 col-sm-8">
							<div class="collections space-y-10 mb-30">
								<a href="javascript:void(0)">
									<div class="collections_item">
										<div class="images-box space-y-10">
											<div class="top_imgs">
												<img
													src="assets/img/items/item_9.png"
													alt="" />
												<img
													src="assets/img/items/item_10.png"
													alt="" />
												<img
													src="assets/img/items/item_11.png"
													alt="" />
											</div>
											<img src="assets/img/items/item_12.png"
												alt="" />
										</div>
									</div>
								</a>
								<div class="collections_footer justify-content-between">
									<h5 class="collection_title"><a href="javascript:void(0)">Creative Art collection</a></h5>
									<a href="#" class="likes space-x-3">
										<i class="ri-heart-3-fill"></i>
										<span class="txt_md">2.1k</span>
									</a>
								</div>
								<div class="creators space-x-10">
									<span class="color_text txt_md"> 5 items · Created by</span>
									<div class="avatars space-x-5">
										<a href="javascript:void(0)">
											<img
												src="assets/img/avatars/avatar_2.png"
												alt="Avatar" class="avatar avatar-sm" />
										</a>
									</div>
									<a href="javascript:void(0)">
										<p class="avatars_name txt_sm"> @william_jamy... </p>
									</a>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-sm-8">
							<div class="collections space-y-10 mb-30">
								<a href="javascript:void(0)">
									<div class="collections_item">
										<div class="images-box space-y-10">
											<div class="top_imgs">
												<img
													src="assets/img/items/item_13.png"
													alt="" />
												<img
													src="assets/img/items/item_14.png"
													alt="" />
												<img
													src="assets/img/items/item_15.png"
													alt="" />
											</div>
											<img src="assets/img/items/item_16.png"
												alt="" />
										</div>
									</div>
								</a>
								<div class="collections_footer justify-content-between">
									<h5 class="collection_title"><a href="javascript:void(0)">Colorful Abstract Painting</a></h5>
									<a href="#" class="likes space-x-3">
										<i class="ri-heart-3-fill"></i>
										<span class="txt_md">3.5k</span>
									</a>
								</div>
								<div class="creators space-x-10">
									<span class="color_text txt_md"> 7 items · Created by</span>
									<div class="avatars space-x-5">
										<a href="javascript:void(0)">
											<img
												src="assets/img/avatars/avatar_3.png"
												alt="Avatar" class="avatar avatar-sm" />
										</a>
									</div>
									<a href="javascript:void(0)">
										<p class="avatars_name txt_sm"> @alexis_fenn... </p>
									</a>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-sm-8">
							<div class="collections space-y-10 mb-30">
								<a href="javascript:void(0)">
									<div class="collections_item">
										<div class="images-box space-y-10">
											<div class="top_imgs">
												<img
													src="assets/img/items/item_17.png"
													alt="" />
												<img
													src="assets/img/items/item_18.png"
													alt="" />
												<img
													src="assets/img/items/item_19.png"
													alt="" />
											</div>
											<img src="assets/img/items/item_20.png"
												alt="" />
										</div>
									</div>
								</a>
								<div class="collections_footer justify-content-between">
									<h5 class="collection_title"><a href="javascript:void(0)">Modern Art collection</a></h5>
									<a href="#" class="likes space-x-3">
										<i class="ri-heart-3-fill"></i>
										<span class="txt_md">7.2k</span>
									</a>
								</div>
								<div class="creators space-x-10">
									<span class="color_text txt_md"> 2 items · Created by</span>
									<div class="avatars space-x-5">
										<a href="javascript:void(0)">
											<img
												src="assets/img/avatars/avatar_1.png"
												alt="Avatar" class="avatar avatar-sm" />
										</a>
									</div>
									<a href="javascript:void(0)">
										<p class="avatars_name txt_sm"> @Joshua_Bren... </p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="call2action">
				<div class="container">
					<div class="row justify-content-between align-items-center
						sm:space-y-20">
						<div class="col-md-6">
							<div class="space-y-20">
								<h1 class="text-white">Start your own
									collection today</h1>
								<p class="color_text section__text">raroin is a shared
									liquidity NFT
									market smart contract
									which
									is used by multiple websites to provide the users the
									best
									possible experience.</p>
								<a href="Connect-javascript:void(0)" class="btn
									btn-primary">Start
									Collecting</a>
							</div>
						</div>
						<div class="col-md-auto">
							<img class="img-fluid img__logo"
								src="assets/img/logos/Logo_illustrstion.png"
								alt="..." />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WalletCardEthers;